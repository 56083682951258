import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, Space, Popconfirm, message, Select } from 'antd';
import axios from 'axios';

const Product = () => {
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const units = ['NOS', 'LTR', 'CM', 'MM', 'KG']; // Units of measurement

  // Fetch products from the server
  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://yogigraphics.in/backend/get_product.php');
      if (response.data.success && Array.isArray(response.data.data)) {
        setProducts(response.data.data);
      } else {
        throw new Error('Data format is incorrect');
      }
    } catch (error) {
      message.error('Failed to fetch products.');
    }
  };

  useEffect(() => {
    fetchProducts(); // Fetch products on component mount
  }, []);

  // Handle form submission
  const handleFinish = async (values) => {
    try {
      if (editingKey) {
        // Editing an existing product
        await axios.post('https://yogigraphics.in/backend/update_product.php', { ...values, id: editingKey });
        message.success('Product updated successfully!');
      } else {
        // Adding a new product
        await axios.post('https://yogigraphics.in/backend/add_product.php', values);
        message.success('Product added successfully!');
      }
      form.resetFields();
      setEditingKey('');
      fetchProducts(); // Refresh the product list
    } catch (error) {
      message.error('Failed to save product.');
    }
  };

  // Handle editing
  const handleEdit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  // Handle deletion
  const handleDelete = async (id) => {
    try {
      await axios.post('https://yogigraphics.in/backend/delete_product.php', { id });
      message.success('Product deleted successfully!');
      fetchProducts(); // Refresh the product list
    } catch (error) {
      message.error('Failed to delete product.');
    }
  };

  // Define columns for the Ant Design Table
  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleEdit(record)} disabled={editingKey !== ''}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this product?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="product-container">
      <div className="product-form">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={editingKey ? products.find(product => product.id === editingKey) : {}}
        >
          <Form.Item
            name="name"
            label="Product Name"
            rules={[{ required: true, message: 'Please enter the product name' }]}
          >
            <Input placeholder="Enter product name" />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: 'Please enter the product price' }]}
          >
            <Input placeholder="Enter price" type="number" />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[{ required: true, message: 'Please enter the product quantity' }]}
          >
            <Input placeholder="Enter quantity" type="number" />
          </Form.Item>
          <Form.Item
            name="unit"
            label="Unit of Measurement"
            rules={[{ required: true, message: 'Please select a unit of measurement' }]}
          >
            <Select placeholder="Select unit">
              {units.map(unit => (
                <Select.Option key={unit} value={unit}>
                  {unit}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingKey ? "Update Product" : "Add Product"}
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="product-list">
        <Table
          columns={columns}
          dataSource={products}
          rowKey="id" // Unique key for each row
        />
      </div>
    </div>
  );
};

export default Product;

// src/Login.js

import React, { useState } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Typography,
  Alert,
  Layout,
  notification,
  Card,
  Row,
  Col,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import logo from "../logo.png"; // Replace with the path to your logo

const { Title, Text } = Typography;
const { Content, Footer } = Layout;

const Login = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (values) => {
    const { email, password } = values;
    try {
      const response = await axios.post(
        "https://yogigraphics.in/backend/login.php",
        {
          email,
          password,
        }
      );
      if (response.data.success) {
        const { token, expiresIn } = response.data;
        const expirationTime = new Date().getTime() + expiresIn * 1000; // expiresIn is in seconds
        localStorage.setItem("authToken", token);
        localStorage.setItem("sessionExpiry", expirationTime);
        notification.success({
          message: "Login Successful",
          description: "You have successfully logged in!",
          placement: "topRight",
        });
        // Redirect to dashboard
        navigate("/dashboard/home");
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      notification.error({
        message: "Login Failed",
        description: "An error occurred. Please try again.",
        placement: "topRight",
      });
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          padding: "0 10px", // Add padding to avoid text touching the edges on small screens
        }}
      >
        {/* Responsive Layout with Ant Design Grid System */}
        <Row justify="center" style={{ width: "100%" }}>
          <Col xs={24} sm={20} md={16} lg={12} xl={8}>
            <Card
              title={
                <div style={{ textAlign: "center" }}>
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      width: "100%",
                      maxWidth: 150,
                      marginBottom: 10,
                      display: "inline-block",
                    }}
                  />
                  <Text
                    type="secondary"
                    style={{ display: "block", marginTop: 5 }}
                  >
                    Welcome back! Please log in to continue.
                  </Text>
                </div>
              }
              bordered={false}
              style={{
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            >
              <Title level={3} style={{ textAlign: "center" }}>
                Login
              </Title>
              <Form
                name="login"
                onFinish={handleSubmit}
                style={{ maxWidth: "100%" }}
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Email"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Password"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Login
                  </Button>
                </Form.Item>
                {error && (
                  <Form.Item>
                    <Alert message={error} type="error" showIcon />
                  </Form.Item>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>

      {/* Footer Section */}
      <Footer
        style={{
          textAlign: "center",
          padding: "10px 50px",
          background: "#f0f2f5",
        }}
      >
        <a href="https://clouddevlopers.xyz/">Powered by CloudDevelopers</a>
        <p>Created by <a href="https://blog.imraaj.xyz/">Raaj</a> with ❤️</p>
      </Footer>
    </Layout>
  );
};

export default Login;

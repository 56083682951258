import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification, Typography, Table, Row, Col, Popconfirm, Modal } from 'antd';
import axios from 'axios';

const { Title } = Typography;

const AddSupplier = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [editForm] = Form.useForm(); // Form for editing supplier
    const [suppliers, setSuppliers] = useState([]);
    const [editingSupplier, setEditingSupplier] = useState(null); // Store the supplier being edited
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        fetchSuppliers();
    }, []);

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get('https://yogigraphics.in/backend/get_supplier.php');
            if (response.data.success) {
                setSuppliers(response.data.data);
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message || 'An error occurred',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to fetch suppliers',
            });
        }
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const response = await axios.post('https://yogigraphics.in/backend/add_supplier.php', values);
            if (response.data.success) {
                notification.success({
                    message: 'Success',
                    description: 'Supplier added successfully',
                });
                form.resetFields();
                fetchSuppliers(); // Refresh the supplier list
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message || 'An error occurred',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to add supplier',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (supplier) => {
        setEditingSupplier(supplier);
        editForm.setFieldsValue(supplier);
        setModalVisible(true);
    };

    const handleEditSubmit = async (values) => {
        setLoading(true);
        try {
            const response = await axios.post('https://yogigraphics.in/backend/update_supplier.php', {
                ...values,
                id: editingSupplier.id
            });
            if (response.data.success) {
                notification.success({
                    message: 'Success',
                    description: 'Supplier updated successfully',
                });
                setModalVisible(false);
                fetchSuppliers(); // Refresh the supplier list
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message || 'An error occurred',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to update supplier',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.post('https://yogigraphics.in/backend/delete_supplier.php', { id });
            if (response.data.success) {
                notification.success({
                    message: 'Success',
                    description: 'Supplier deleted successfully',
                });
                fetchSuppliers(); // Refresh the supplier list
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message || 'An error occurred',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to delete supplier',
            });
        }
    };

    const columns = [
        {
            title: 'Supplier Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Contact Phone',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button onClick={() => handleEdit(record)} style={{ marginRight: 8 }}>Edit</Button>
                    <Popconfirm
                        title="Are you sure you want to delete this supplier?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger>Delete</Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <div style={{ padding: '20px' }}>
            <Title level={2}>Add Supplier</Title>
            <Row gutter={16}>
                <Col span={12}>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        layout="vertical"
                        style={{ maxWidth: '600px' }}
                    >
                        <Form.Item
                            name="name"
                            label="Supplier Name"
                            rules={[{ required: true, message: 'Please enter the supplier name' }]}
                        >
                            <Input placeholder="Enter supplier name" />
                        </Form.Item>

                        <Form.Item
                            name="number"
                            label="Contact Phone"
                            rules={[{ required: true, message: 'Please enter the contact phone number' }]}
                        >
                            <Input placeholder="Enter contact phone number" />
                        </Form.Item>

                        <Form.Item
                            name="address"
                            label="Address"
                        >
                            <Input.TextArea rows={4} placeholder="Enter supplier address" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                style={{ width: '100%' }}
                            >
                                Add Supplier
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={12}>
                    <Table
                        columns={columns}
                        dataSource={suppliers}
                        rowKey="id" // Ensure 'id' is present in the supplier data
                        pagination={{ pageSize: 5 }}
                        style={{ marginTop: '20px' }}
                    />
                </Col>
            </Row>

            {/* Edit Supplier Modal */}
            <Modal
                title="Edit Supplier"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                destroyOnClose
            >
                <Form
                    form={editForm}
                    onFinish={handleEditSubmit}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label="Supplier Name"
                        rules={[{ required: true, message: 'Please enter the supplier name' }]}
                    >
                        <Input placeholder="Enter supplier name" />
                    </Form.Item>

                    <Form.Item
                        name="number"
                        label="Contact Phone"
                        rules={[{ required: true, message: 'Please enter the contact phone number' }]}
                    >
                        <Input placeholder="Enter contact phone number" />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        label="Address"
                    >
                        <Input.TextArea rows={4} placeholder="Enter supplier address" />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            style={{ width: '100%' }}
                        >
                            Save Changes
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AddSupplier;

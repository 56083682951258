import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  InputNumber,
  notification,
  Row,
  Col,
  Select,
  Typography,
  Divider,
  DatePicker,
} from "antd";
import logo from "../logo.png";
import moment from "moment";
import qrCodeUrl from "./gpay.jpeg";

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const InvoiceForm = () => {
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [count, setCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [advance, setAdvance] = useState(0);
  const [invoiceDate, setInvoiceDate] = useState(moment());
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [filteredProductOptions, setFilteredProductOptions] =
    useState(productOptions);
  const [filteredCustomerOptions, setFilteredCustomerOptions] =
    useState(customers);
  const [customerDetails, setCustomerDetails] = useState(null);

  const handleSearch = (value) => {
    if (value) {
      const filteredProducts = productOptions.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProductOptions(filteredProducts);
    } else {
      setFilteredProductOptions(productOptions);
    }
  };

  const handleSearchCustomer = (value) => {
    if (value) {
      const filteredCustomers = customers.filter((customer) =>
        customer.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCustomerOptions(filteredCustomers);
    } else {
      setFilteredCustomerOptions(customers);
    }
  };

  const fetchRemainingAmount = async (customerId) => {
    try {
      const response = await fetch(
        `https://yogigraphics.in/backend/get_remaining_amount.php?customer_name=${customerId}`
      );
      const result = await response.json();
      setCustomerDetails(result);
    } catch (error) {
      console.error("Failed to fetch remaining amount:", error);
      setCustomerDetails(null);
    }
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(
          "https://yogigraphics.in/backend/get_customer.php"
        );
        const result = await response.json();
        if (result.success) {
          setCustomers(result.data);
          setFilteredCustomerOptions(result.data); // Initialize filtered options
        } else {
          notification.error({
            message: "Error",
            description: result.message,
          });
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: "Failed to fetch customers",
        });
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch(
          "https://yogigraphics.in/backend/get_product.php"
        );
        const result = await response.json();
        if (result.success) {
          setProductOptions(result.data);
        } else {
          notification.error({
            message: "Error",
            description: result.message,
          });
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: "Failed to fetch products",
        });
      }
    };

    fetchCustomers();
    fetchProducts();

    const savedInvoiceNumber = localStorage.getItem("invoiceNumber");
    if (savedInvoiceNumber) {
      setInvoiceNumber(savedInvoiceNumber);
    } else {
      const defaultInvoiceNumber = "INV0001"; // You can set this to whatever format you prefer
      localStorage.setItem("invoiceNumber", defaultInvoiceNumber);
      setInvoiceNumber(defaultInvoiceNumber);
    }
  }, []);

  useEffect(() => {
    const computedTotal = products.reduce((total, product) => {
      return total + product.quantity * product.price;
    }, 0);
    setTotalAmount(computedTotal - advance);
  }, [products, advance]);

  const addProduct = () => {
    setProducts([
      ...products,
      { key: count, product: "", quantity: 1, price: 0 },
    ]);
    setCount(count + 1);
  };

  const handleProductChange = (key, field, value) => {
    setProducts(
      products.map((p) => (p.key === key ? { ...p, [field]: value } : p))
    );
  };
  const handleRemoveProduct = (key) => {
    // Assuming you have a state management for products
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.key !== key)
    );
  };

  const handleCustomerChange = async (customerName) => {
    try {
      const response = await fetch(
        "https://yogigraphics.in/backend/get_customer.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: customerName }),
        }
      );
      const result = await response.json();
      if (result.success) {
        const customer = result.data;
        form.setFieldsValue({
          address: customer.address,
          number: customer.number,
        });
        fetchRemainingAmount(customerName);

      } else {
        notification.error({
          message: "Error",
          description: result.message,
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to fetch customer details",
      });
    }
  };

  const handleProductSelect = (key, productName) => {
    const selectedProduct = productOptions.find((p) => p.name === productName);
    if (selectedProduct) {
      setProducts(
        products.map((p) =>
          p.key === key
            ? { ...p, product: productName, price: selectedProduct.price }
            : p
        )
      );
    }
  };

  const handleSubmitAndPrint = async (values) => {
    try {
      const stockResponse = await fetch(
        "https://yogigraphics.in/backend/get_product_stock.php"
      );
      const stockResult = await stockResponse.json();
      if (!stockResult.success) {
        throw new Error(stockResult.message);
      }

      const stockMap = new Map(
        stockResult.data.map((product) => [product.name, product.quantity])
      );

      for (const product of products) {
        const availableStock = stockMap.get(product.product) || 0;
        if (product.quantity > availableStock) {
          notification.error({
            message: "Error",
            description: `Insufficient stock for product ${product.product}. Available quantity: ${availableStock}.`,
          });
          return;
        }
      }

      const invoiceResponse = await fetch(
        "https://yogigraphics.in/backend/add_invoice.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            invoiceNumber: invoiceNumber, // Use the state value here
            customer: values.customer,
            address: values.address,
            number: values.number,
            totalAmount: totalAmount,
            products,
            narration: values.narration,
            advance: advance, // Include advance amount
            invoiceDate: invoiceDate ? invoiceDate.format("YYYY-MM-DD") : null,
          }),
        }
      );

      const invoiceResult = await invoiceResponse.json();

      if (!invoiceResult.success) {
        throw new Error(invoiceResult.message);
      }

      await Promise.all(
        products.map(async (product) => {
          const productUpdateResponse = await fetch(
            "https://yogigraphics.in/backend/update_product.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                name: product.product,
                quantity: -product.quantity,
              }),
            }
          );

          const productUpdateResult = await productUpdateResponse.json();

          if (!productUpdateResult.success) {
            throw new Error(productUpdateResult.message);
          }
        })
      );

      // Increment invoice number only if it's in the expected format
      const incrementInvoiceNumber = (currentInvoiceNumber) => {
        const match = currentInvoiceNumber.match(/^INV(\d+)$/);
        if (match) {
          const nextNumber = (parseInt(match[1], 10) + 1)
            .toString()
            .padStart(4, "0");
          return `INV${nextNumber}`;
        }
        return currentInvoiceNumber;
      };

      const newInvoiceNumber = incrementInvoiceNumber(invoiceNumber);
      localStorage.setItem("invoiceNumber", newInvoiceNumber);
      setInvoiceNumber(newInvoiceNumber);

      notification.success({
        message: "Success",
        description: "Invoice added and inventory updated successfully",
      });

      printInvoice();
      form.resetFields();
      setProducts([]);
      setInvoiceDate(moment()); // Reset to current date
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An unexpected error occurred: " + error.message,
      });
    }
  };

  const convertNumberToWords = (number) => {
    const ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand", "Million", "Billion"];

    if (number === 0) return "Zero";

    let words = "";
    let i = 0;

    while (number > 0) {
      if (number % 1000 !== 0) {
        words = `${convertHundreds(number % 1000)} ${thousands[i]} ${words}`;
      }
      number = Math.floor(number / 1000);
      i++;
    }

    return words.trim();

    function convertHundreds(num) {
      let str = "";
      if (num > 99) {
        str += ones[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }
      if (num > 19) {
        str += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }
      if (num > 0) {
        str += ones[num] + " ";
      }
      return str.trim();
    }
  };
  const printInvoice = () => {
    const invoiceHtml = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Invoice</title>
            <style>
                @page {
                    size: A4;
                    margin: 20mm;
                }
                body {
                    font-family: Arial, sans-serif;
                    color: #000;
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    background-color: #f4f4f4;
                }
                .container {
                    width: 100%;
                    max-width: 800px;
                    margin: 0 auto;
                    padding: 20px;
                    border: 1px solid #ddd;
                    background: #fff;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    position: relative;
                    border-radius: 8px;
                }
                .header {
                    text-align: center;
                    margin-bottom: 20px;
                    border-bottom: 2px solid #000;
                    padding-bottom: 10px;
                }
                .header img {
                    max-width: 120px;
                }
                .header h1 {
                    margin: 10px 0;
                    font-size: 26px;
                    color: #333;
                }
                .company-details, .invoice-details {
                    margin-bottom: 20px;
                    font-size: 14px;
                }
                .company-details p, .invoice-details p {
                    margin: 5px 0;
                }
                .company-details {
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 10px;
                }
                .invoice-details {
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 10px;
                }
                .table-container {
                    margin-bottom: 40px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    font-size: 14px;
                    margin-bottom: 20px;
                }
                table, th, td {
                    border: 1px solid #ddd;
                }
                th, td {
                    padding: 10px;
                    text-align: left;
                }
                th {
                    background-color: #f4f4f4;
                    font-weight: bold;
                }
                .total-row {
                    font-weight: bold;
                    background-color: #f4f4f4;
                    text-align: right;
                    border-top: 2px solid #000;
                }            .footer {
                    font-size: 12px;
                    text-align: center;
                    margin-top: 20px;
                    padding-top: 10px;
                    border-top: 1px solid #ddd;
                }
                .footer p {
                    margin: 5px 0;
                    color: #666;
                }
                .amount-words {
                    font-style: italic;
                    font-size: 12px;
                    margin-top: 10px;
                    text-align: left;
                }
                .signatory {
                    margin-top: 40px;
                    text-align: right;
                    font-size: 12px;
                }
                .qr-code {
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    max-width: 100px;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <!-- Header with Company Logo and Title -->
                <div class="header">
                    <img src="${logo}" alt="Company Logo" />
                    <h1>Invoice</h1>
                </div>
                <!-- Company Details -->
                <div class="company-details">
                    <p><strong>Yogi Graphics</strong></p>
                    <p>8 Ramnath Mahadev Shopping Center</p>
                    <p>Nr. Busstand, Zakli Talav, Borsad 388580</p>
                    <p>Phone: +91 9825514255 | Email: yogiborsad@gmail.com</p>
                    <p><strong>Jurisdiction:</strong> Borsad, Gujarat</p>
                </div>
                <!-- Invoice Details -->
                <div class="invoice-details">
                    <p><strong>Invoice Number:</strong> ${invoiceNumber}</p>
                    <p><strong>Date:</strong> ${
                      invoiceDate ? invoiceDate.format("DD-MMM-YY") : ""
                    }</p>
                    <p><strong>Customer:</strong> ${form.getFieldValue(
                      "customer"
                    )}</p>
                    <p><strong>Address:</strong> ${form.getFieldValue(
                      "address"
                    )}</p>
                    <p><strong>Phone Number:</strong> ${form.getFieldValue(
                      "number"
                    )}</p>
                    <p><strong>Advance:</strong> ${advance.toFixed(2)}</p>
                    <p><strong>Narration:</strong> ${form.getFieldValue(
                      "narration"
                    )}</p>
                      <p><strong>Previous Remaining Amount:</strong> ₹${parseFloat(customerDetails.total_paid_amount).toFixed(2)}</p>

                </div>
                <!-- Items Table -->
                <div class="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${products
                              .map((product) => {
                                const price = parseFloat(product.price) || 0;
                                const quantity =
                                  parseInt(product.quantity, 10) || 0;
                                const total = (quantity * price).toFixed(2);
                                return `
                                    <tr>
                                        <td>${product.product}</td>
                                        <td>${quantity}</td>
                                        <td>${price.toFixed(2)}</td>
                                        <td>${total}</td>
                                    </tr>
                                `;
                              })
                              .join("")}
                            <tr class="total-row">
                                <td colspan="3">Total Amount</td>
                                <td>${totalAmount.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>

                  <!-- Footer -->
                  <div class="footer">
                      <p>This is a Computer Generated Invoice</p>
                  </div>

                  <!-- Signatory -->
                  <div class="signatory">
                      <p>for Yogi Graphics</p>
                      <p>Authorised Signatory</p>
                  </div>

                  <!-- QR Code for Google Pay -->
                  <img src="${qrCodeUrl}" alt="Google Pay QR Code" class="qr-code" />
              </div>
        </body>
        </html>
        `;

    const printWindow = window.open("", "", "height=800,width=600");
    printWindow.document.open();
    printWindow.document.write(invoiceHtml);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div>
      <Title level={2}>Invoice Form</Title>
      <Form form={form} onFinish={handleSubmitAndPrint} layout="vertical">
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Invoice Number">
              <Input
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="customer"
              label="Customer Name"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="Select or type a customer"
                onChange={handleCustomerChange}
                onSearch={handleSearchCustomer}
                filterOption={false} // Disable default filtering
              >
                {filteredCustomerOptions.map((customer) => (
                  <Option key={customer.name} value={customer.name}>
                    {customer.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="number" label="Contact Number">
              <Input />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Invoice Date">
              <DatePicker
                value={invoiceDate}
                onChange={(date) => setInvoiceDate(date)}
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        {products.map((product) => (
          <Form.Item key={product.key} style={{ marginBottom: 0 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Form.Item label="Product" style={{ flex: 2, marginBottom: 0 }}>
                <Select
                  value={product.product}
                  onChange={(value) => handleProductSelect(product.key, value)}
                  onSearch={handleSearch}
                  showSearch
                  filterOption={false} // Disable default filtering
                  placeholder="Select or type a product"
                  style={{ width: "100%" }}
                  dropdownStyle={{ width: 300 }} // Adjust the width of the dropdown menu if needed
                  notFoundContent={null} // Hide the "Not Found" content
                >
                  {filteredProductOptions.map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Quantity"
                style={{ marginLeft: "5px", marginBottom: 0 }}
              >
                <InputNumber
                  value={product.quantity}
                  onChange={(value) =>
                    handleProductChange(product.key, "quantity", value)
                  }
                  min={1}
                  placeholder="Quantity"
                />
              </Form.Item>
              <Form.Item
                label="Price"
                style={{ marginLeft: "5px", marginBottom: 0 }}
              >
                <InputNumber
                  value={product.price}
                  onChange={(value) =>
                    handleProductChange(product.key, "price", value)
                  }
                  min={0}
                  step={0.01}
                  placeholder="Price"
                />
              </Form.Item>
              <Form.Item
                label="Total"
                style={{ flex: 1, marginLeft: "5px", marginBottom: 0 }}
              >
                <Input
                  value={(product.quantity * product.price).toFixed(2)}
                  readOnly
                  placeholder="Total"
                />
              </Form.Item>
              <Form.Item
                label=" "
                style={{ marginLeft: "5px", marginBottom: 0 }}
              >
                <Button
                  type="primary" // Use primary for a prominent color, or customize style
                  danger
                  onClick={() => handleRemoveProduct(product.key)}
                >
                  Remove
                </Button>
              </Form.Item>
            </div>
          </Form.Item>
        ))}

        <Button
          type="dashed"
          onClick={addProduct}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          Add Product
        </Button>

        <Form.Item label="Advance">
          <InputNumber
            value={advance}
            onChange={(value) => setAdvance(value)}
            min={0}
            step={0.01}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name="narration" label="Narration">
  <Input />
</Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "bold",
            }}
          >
            <div>Total Amount:</div>
            <div>{totalAmount.toFixed(2)}</div>
          </div>
        </Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "10%" }}>
          Generate Invoice
        </Button>
      </Form>
    </div>
  );
};

export default InvoiceForm;

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Table, Space, Popconfirm, message } from 'antd';
import axios from 'axios'; // Import axios for HTTP requests
import './customer.css'; // Import CSS for custom styling if needed

const Customer = () => {
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  // Fetch customers from the server
  const fetchCustomers = async () => {
    try {
      const response = await axios.get('https://yogigraphics.in/backend/get_customer.php');
      if (response.data.success && Array.isArray(response.data.data)) {
        setCustomers(response.data.data);
      } else {
        throw new Error('Data format is incorrect');
      }
    } catch (error) {
      message.info('Failed to fetch customers.');
    }
  };
  

  useEffect(() => {
    fetchCustomers(); // Fetch customers on component mount
  }, []);

  // Handle form submission
  const handleFinish = async (values) => {
    try {
      if (editingKey) {
        // Editing an existing customer
        await axios.post('https://yogigraphics.in/backend/update_customer.php', { ...values, name: editingKey });
        message.success('Customer updated successfully!');
      } else {
        // Adding a new customer
        await axios.post('https://yogigraphics.in/backend/add_customer.php', values);
        message.success('Customer added successfully!');
      }
      form.resetFields();
      setEditingKey('');
      fetchCustomers(); // Refresh the customer list
    } catch (error) {
      message.error('Failed to save customer.');
    }
  };

  // Handle editing
  const handleEdit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.name);
  };

  // Handle deletion
  const handleDelete = async (name) => {
    try {
      const response = await axios.post('https://yogigraphics.in/backend/delete_customer.php', { name }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data.success) {
        message.success('Customer deleted successfully!');
        fetchCustomers(); // Refresh the customer list
      } else {
        message.error('Failed to delete customer: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error deleting customer:', error);
      message.error('Failed to delete customer.');
    }
  };
  

  // Define columns for the Ant Design Table
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => handleEdit(record)}
            disabled={editingKey !== ''}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this customer?"
            onConfirm={() => handleDelete(record.name)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];


  return (
    <div className="customer-container">
      <div className="customer-form">
        <Card title={editingKey ? "Edit Customer" : "Add New Customer"}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={editingKey ? customers.find(customer => customer.name === editingKey) : {}}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter the customer name' }]}
            >
              <Input placeholder="Enter customer name" />
            </Form.Item>
            <Form.Item
              name="number"
              label="Phone Number"
              rules={[{ required: true, message: 'Please enter the customer phone number' }]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Please enter the customer address' }]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: 'Please enter the customer city' }]}
            >
              <Input placeholder="Enter city" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {editingKey ? "Update Customer" : "Add Customer"}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>

      <div className="customer-list">
        <Card title="Customer List">
          <Table
            columns={columns}
            dataSource={customers}
            rowKey="name" // Unique key for each row
          />
        </Card>
      </div>
    </div>
  );
};

export default Customer;

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, InputNumber, notification, Typography, Table, Input as AntdInput } from 'antd';
import axios from 'axios';

const { Title } = Typography;

const AdvancePaymentForm = () => {
  const [form] = Form.useForm();
  const [totalPay, setTotalPay] = useState(0);
  const [advancePay, setAdvancePay] = useState(0);
  const [remainingPay, setRemainingPay] = useState(0);
  const [narration, setNarration] = useState('');
  const [payments, setPayments] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    try {
      const response = await axios.get('https://yogigraphics.in/backend/get_payments.php');
      if (response.data.success) {
        setPayments(response.data.data);
      } else {
        notification.error({
          message: 'Error',
          description: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching payments',
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      const url = editingId ? 'https://yogigraphics.in/backend/update_payment.php' : 'https://yogigraphics.in/backend/create_payment.php';
      const method = editingId ? 'PUT' : 'POST';
      const data = {
        id: editingId,
        name: values.name,
        totalPay: values.totalPay,
        advancePay: values.advancePay,
        remainingPay: values.remainingPay,
        narration: values.narration,
      };

      const response = await axios({
        method,
        url,
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      });

      if (response.data.success) {
        notification.success({
          message: 'Success',
          description: `Payment details ${editingId ? 'updated' : 'saved'} successfully!`,
        });

        form.resetFields();
        setTotalPay(0);
        setAdvancePay(0);
        setRemainingPay(0);
        setNarration('');
        setEditingId(null);
        fetchPayments();
      } else {
        notification.error({
          message: 'Error',
          description: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An unexpected error occurred',
      });
    }
  };

  const handleEdit = (record) => {
    form.setFieldsValue({
      name: record.name,
      totalPay: record.total_pay,
      advancePay: record.advance_pay,
      remainingPay: record.remaining_pay,
      narration: record.narration,
    });
    setTotalPay(record.total_pay);
    setAdvancePay(record.advance_pay);
    setRemainingPay(record.remaining_pay);
    setNarration(record.narration);
    setEditingId(record.id);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.post('https://yogigraphics.in/backend/delete_payment.php', {
        action: 'delete_payment',
        id,
      });
      if (response.data.success) {
        notification.success({
          message: 'Success',
          description: 'Payment details deleted successfully!',
        });
        fetchPayments();
      } else {
        notification.error({
          message: 'Error',
          description: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An unexpected error occurred',
      });
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredPayments = payments.filter(payment => 
    payment.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Total Payment', dataIndex: 'total_pay', key: 'total_pay' },
    { title: 'Advance Payment', dataIndex: 'advance_pay', key: 'advance_pay' },
    { title: 'Remaining Payment', dataIndex: 'remaining_pay', key: 'remaining_pay' },
    { title: 'Narration', dataIndex: 'narration', key: 'narration' },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button onClick={() => handleEdit(record)} type="link">Edit</Button>
          <Button onClick={() => handleDelete(record.id)} type="link" danger>Delete</Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Title level={2}>Advance Payment Form</Title>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the name' }]}
        >
          <Input placeholder="Enter the name" />
        </Form.Item>
        <Form.Item
          name="totalPay"
          label="Total Payment"
          rules={[{ required: true, message: 'Please enter the total payment' }]}
        >
          <InputNumber
            min={0}
            step={0.01}
            value={totalPay}
            onChange={value => setTotalPay(value)}
            placeholder="Enter the total payment"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="advancePay"
          label="Advance Payment"
          rules={[{ required: true, message: 'Please enter the advance payment' }]}
        >
          <InputNumber
            min={0}
            step={0.01}
            value={advancePay}
            onChange={value => setAdvancePay(value)}
            placeholder="Enter the advance payment"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="remainingPay"
          label="Remaining Payment"
          rules={[{ required: true, message: 'Please enter the remaining payment' }]}
        >
          <InputNumber
            min={0}
            step={0.01}
            value={remainingPay}
            onChange={value => setRemainingPay(value)}
            placeholder="Enter the remaining payment"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="narration"
          label="Narration"
        >
          <Input.TextArea
            value={narration}
            onChange={e => setNarration(e.target.value)}
            placeholder="Enter narration"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingId ? 'Update Payment Details' : 'Save Payment Details'}
          </Button>
        </Form.Item>
      </Form>
      <AntdInput.Search
        placeholder="Search by name"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 16, width: 300 }}
      />
      <Table dataSource={filteredPayments} columns={columns} rowKey="id" />
    </div>
  );
};

export default AdvancePaymentForm;

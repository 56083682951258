// src/components/SessionManager.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isSessionExpired } from '../utils/session';

const SessionManager = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = () => {
      if (isSessionExpired()) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('sessionExpiry');
        navigate('/login');
      }
    };

    checkSession();

    const intervalId = setInterval(checkSession, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [navigate]);

  return children;
};

export default SessionManager;

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, InputNumber, notification, Typography, Select, DatePicker, AutoComplete } from 'antd';
import logo from '../logo.png';
import moment from 'moment';
import qrCodeUrl from './gpay.jpeg';

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const PurchaseInvoiceForm = () => {
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [suppliers, setSuppliers] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(moment());
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [advancePayment, setAdvancePayment] = useState(0);
  const [remainingPayment, setRemainingPayment] = useState(0);

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await fetch('https://yogigraphics.in/backend/get_supplier.php');

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();

        if (result.success) {
          setSuppliers(result.data);
        } else {
          notification.error({
            message: 'Error',
            description: result.message,
          });
        }
      } catch (error) {
        console.error('Error fetching suppliers:', error);
        notification.error({
          message: 'Error',
          description: 'Failed to fetch suppliers',
        });
      }
    };

    fetchSuppliers();

    const savedInvoiceNumber = localStorage.getItem('purchaseInvoiceNumber');
    if (savedInvoiceNumber) {
      setInvoiceNumber(savedInvoiceNumber);
    } else {
      const defaultInvoiceNumber = 'PINV0001';
      localStorage.setItem('purchaseInvoiceNumber', defaultInvoiceNumber);
      setInvoiceNumber(defaultInvoiceNumber);
    }
  }, []);

  useEffect(() => {
    const computedTotal = products.reduce((total, product) => {
      return total + (product.quantity * product.price);
    }, 0);
    setTotalAmount(computedTotal);
    setRemainingPayment(computedTotal - advancePayment);
  }, [products, advancePayment]);

  const addProduct = () => {
    setProducts([...products, { key: count, product: '', quantity: 1, price: 0 }]);
    setCount(count + 1);
  };

  const handleProductChange = (key, field, value) => {
    setProducts(products.map(p =>
      p.key === key ? { ...p, [field]: value } : p
    ));
  };

  const handleRemoveProduct = (key) => {
    setProducts(prevProducts => prevProducts.filter(product => product.key !== key));
  };

  const handleSupplierChange = (supplierName) => {
    const selectedSupplier = suppliers.find(supplier => supplier.name === supplierName);
    if (selectedSupplier) {
      form.setFieldsValue({
        address: selectedSupplier.address,
        number: selectedSupplier.number,
      });
    }
  };

  const handleSubmitAndPrint = async (values) => {
    try {
      const purchaseResponse = await fetch('https://yogigraphics.in/backend/add_purchase_invoice.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          invoiceNumber: invoiceNumber,
          supplier: values.supplier,
          address: values.address,
          number: values.number,
          totalAmount: totalAmount,
          advancePayment: advancePayment,
          remainingPayment: remainingPayment,
          products,
          invoiceDate: invoiceDate ? invoiceDate.format('YYYY-MM-DD') : null,
          narration: values.narration,
        }),
      });

      const purchaseResult = await purchaseResponse.json();

      if (!purchaseResult.success) {
        throw new Error(purchaseResult.message);
      }

      const incrementInvoiceNumber = (currentInvoiceNumber) => {
        const match = currentInvoiceNumber.match(/^PINV(\d+)$/);
        if (match) {
          const nextNumber = (parseInt(match[1], 10) + 1).toString().padStart(4, '0');
          return `PINV${nextNumber}`;
        }
        return currentInvoiceNumber;
      };

      const newInvoiceNumber = incrementInvoiceNumber(invoiceNumber);
      localStorage.setItem('purchaseInvoiceNumber', newInvoiceNumber);
      setInvoiceNumber(newInvoiceNumber);

      notification.success({
        message: 'Success',
        description: 'Purchase invoice added successfully',
      });

      printInvoice(values.narration);
      form.resetFields();
      setProducts([]);
      setInvoiceDate(moment());
      setAdvancePayment(0);
      setRemainingPayment(0);

    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An unexpected error occurred: ' + error.message,
      });
    }
  };

  const convertNumberToWords = (number) => {
    const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const thousands = ["", "Thousand", "Million", "Billion"];
  
    if (number === 0) return "Zero";
  
    let words = "";
    let i = 0;
  
    while (number > 0) {
      if (number % 1000 !== 0) {
        words = `${convertHundreds(number % 1000)} ${thousands[i]} ${words}`;
      }
      number = Math.floor(number / 1000);
      i++;
    }
  
    return words.trim();
  
    function convertHundreds(num) {
      let str = "";
      if (num > 99) {
        str += ones[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }
      if (num > 19) {
        str += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }
      if (num > 0) {
        str += ones[num] + " ";
      }
      return str.trim();
    }
  };

  const printInvoice = (narration) => {
    const amountInWords = convertNumberToWords(totalAmount);

    const invoiceHtml = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Purchase Invoice</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
            }
            .container {
                width: 100%;
                max-width: 800px;
                margin: 0 auto;
                padding: 20px;
                border: 1px solid #ccc;
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                position: relative;
            }
            .header {
                text-align: center;
                margin-bottom: 20px;
            }
            .header img {
                width: 100px;
            }
            .company-details, .invoice-details {
                margin-bottom: 20px;
            }
            .company-details p, .invoice-details p {
                margin: 5px 0;
            }
            .invoice-details {
                border-top: 1px solid #ddd;
                padding-top: 10px;
            }
            .table-container {
                margin-bottom: 20px;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
            }
            th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
            }
            th {
                background-color: #f4f4f4;
            }
            .total-row {
                font-weight: bold;
            }
            .footer {
                font-size: 12px;
                text-align: center;
                margin-top: 20px;
                padding-top: 10px;
                border-top: 1px solid #ddd;
            }
            .footer p {
                margin: 5px 0;
                color: #666;
            }
            .amount-words {
                font-style: italic;
                font-size: 12px;
                margin-top: 10px;
                text-align: left;
            }
            .signatory {
                margin-top: 40px;
                text-align: right;
                font-size: 12px;
            }
            .qr-code {
                position: absolute;
                bottom: 20px;
                left: 20px;
                max-width: 100px;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="header">
                <img src="${logo}" alt="Company Logo" />
                <h1>Purchase Invoice</h1>
            </div>
            <div class="company-details">
                <p><strong>Yogi Graphics</strong></p>
                <p>8 Ramnath Mahadev Shopping Center</p>
                <p>Nr. Busstand, Zakli Talav, Borsad 388580</p>
                <p>Phone: +91 9825514255 | Email: yogiborsad@gmail.com</p>
                <p><strong>Jurisdiction:</strong> Borsad, Gujarat</p>
            </div>
            <div class="invoice-details">
                <p><strong>Invoice Number:</strong> ${invoiceNumber}</p>
                <p><strong>Date:</strong> ${invoiceDate ? invoiceDate.format('DD-MMM-YY') : ''}</p>
                <p><strong>Supplier:</strong> ${form.getFieldValue('supplier')}</p>
                <p><strong>Address:</strong> ${form.getFieldValue('address')}</p>
                <p><strong>Phone Number:</strong> ${form.getFieldValue('number')}</p>
            </div>
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${products.map(product => {
      const price = parseFloat(product.price) || 0;
      const quantity = parseInt(product.quantity, 10) || 0;
      const total = (quantity * price).toFixed(2);
      return ` 
                                <tr>
                                    <td>${product.product}</td>
                                    <td>${quantity}</td>
                                    <td>${price.toFixed(2)}</td>
                                    <td>${total}</td>
                                </tr>
                            `;
    }).join('')}
                        <tr class="total-row">
                            <td colspan="3">Total Amount</td>
                            <td>${totalAmount.toFixed(2)}</td>
                        </tr>
                        <tr class="total-row">
                            <td colspan="3">Advance Payment</td>
                            <td>${advancePayment.toFixed(2)}</td>
                        </tr>
                        <tr class="total-row">
                            <td colspan="3">Remaining Payment</td>
                            <td>${remainingPayment.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
                <!-- Amount in Words -->
                <div class="amount-words">
                    <p><strong>Amount Chargeable (in words):</strong> ${amountInWords} Only</p>
                </div>
                <!-- Narration -->
                <div class="amount-words">
                    <p><strong>Narration:</strong> ${narration || ''}</p>
                </div>
                <!-- Footer -->
                <div class="footer">
                    <p>This is a Computer Generated Invoice</p>
                </div>
                <!-- Signatory -->
                <div class="signatory">
                    <p>for Yogi Graphics</p>
                    <p>Authorised Signatory</p>
                </div>
                <!-- QR Code for Google Pay -->
                <img src="${qrCodeUrl}" alt="Google Pay QR Code" class="qr-code" />
            </div>
        </div>
    </body>
    </html>`;

    const printWindow = window.open('', '', 'height=800,width=1200');
    printWindow.document.open();
    printWindow.document.write(invoiceHtml);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  return (
    <div>
      <Title level={2}>Purchase Invoice Form</Title>
      <Form form={form} onFinish={handleSubmitAndPrint} layout="vertical">
        <Form.Item label="Invoice Number">
          <Input
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
          />
        </Form.Item>
        <Form.Item name="invoiceDate" label="Invoice Date" rules={[{ required: true }]}>
          <DatePicker
            value={invoiceDate}
            onChange={date => setInvoiceDate(date)}
            format="DD-MMM-YYYY"
          />
        </Form.Item>
        <Form.Item name="supplier" label="Supplier Name" rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder="Select a supplier"
            onChange={handleSupplierChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {suppliers.map(supplier => (
              <Option key={supplier.id} value={supplier.name}>
                {supplier.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="address" label="Address">
          <Input />
        </Form.Item>
        <Form.Item name="number" label="Contact Number">
          <Input />
        </Form.Item>
        <Form.Item name="narration" label="Narration">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="advancePayment" label="Advance Payment">
          <InputNumber
            min={0}
            step={0.01}
            value={advancePayment}
            onChange={value => setAdvancePayment(value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="dashed" onClick={addProduct}>Add Product</Button>
        </Form.Item>
        {products.map((product, index) => (
          <Form.Item key={product.key} style={{ marginBottom: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Form.Item label="Product" style={{ flex: 2, marginBottom: 0 }}>
                <Input
                  value={product.product}
                  onChange={e => handleProductChange(product.key, 'product', e.target.value)}
                  placeholder="Enter Product Name"
                />
              </Form.Item>
              <Form.Item label="Quantity" style={{ marginLeft: '5px', marginBottom: 0 }}>
                <InputNumber
                  min={1}
                  value={product.quantity}
                  onChange={value => handleProductChange(product.key, 'quantity', value)}
                />
              </Form.Item>
              <Form.Item label="Price" style={{ marginLeft: '5px', marginBottom: 0 }}>
                <InputNumber
                  min={0}
                  step={0.01}
                  value={product.price}
                  onChange={value => handleProductChange(product.key, 'price', value)}
                />
              </Form.Item>
              <Button
                type="link"
                onClick={() => handleRemoveProduct(product.key)}
                style={{ marginLeft: '10px' }}
              >
                Remove
              </Button>
            </div>
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit and Print</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PurchaseInvoiceForm;

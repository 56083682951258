import React, { useState } from 'react';
import { Layout, Menu, Button, Typography, Drawer } from 'antd';
import { MenuOutlined, LogoutOutlined, HomeOutlined, FileTextOutlined, UserOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { useNavigate, Outlet } from 'react-router-dom';
import '../dashboard/dashboard.css';

const { Header, Content } = Layout;
const { Title } = Typography;

const Dashboard = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const handleMenuClick = (e) => {
    navigate(`/dashboard/${e.key}`);
    setDrawerVisible(false); // Close drawer on menu item click
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Drawer
        title={<Title level={3} style={{ color: '#001529' }}>Yogi Graphics</Title>}
        placement="left"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        bodyStyle={{ padding: 0 }}
        headerStyle={{ background: '#fff' }}
        width={200}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={['home']}
          style={{ height: '100%', borderRight: 0 }}
          onClick={handleMenuClick}
        >
          <Menu.Item key="home" icon={<HomeOutlined />} >
            Home
          </Menu.Item>
          <Menu.Item key="invoice" icon={<FileTextOutlined />} >
            Invoice
          </Menu.Item>
          <Menu.Item key="customer" icon={<UserOutlined />} >
            Customer
          </Menu.Item>
          <Menu.Item key="product" icon={<AppstoreAddOutlined />} >
            Product
          </Menu.Item>
          <Menu.Item key="psh" icon={<FileTextOutlined />} >
            Purchase Invoice
          </Menu.Item>
          <Menu.Item key="addsupplier" icon={<UserOutlined />} >
            Add Supplier
          </Menu.Item>
          <Menu.Item key="purchaseinvoicehistory" icon={<FileTextOutlined />} >
            Purchase Invoice History
          </Menu.Item>
          <Menu.Item key="salary" icon={<UserOutlined />} >
            Salary
          </Menu.Item>
        </Menu>
      </Drawer>
      <Layout>
        <Header className="site-layout-background" style={{ padding: '0 16px', background: '#fff', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="text"
            icon={<MenuOutlined />}
            style={{ fontSize: '16px', width: 64, height: 64 }}
            onClick={toggleDrawer}
          />
          <Button
            type="text"
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            style={{ marginTop: 16 }}
          >
            Logout
          </Button>
        </Header>
        <Content style={{ margin: '16px 24px' }}>
          <div style={{ padding: 24, minHeight: 360, background: '#fff', borderRadius: '8px' }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;

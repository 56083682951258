import React, { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Spin,
  notification,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Popconfirm,
  Select,
  Row,
  Col,
} from "antd";
import { saveAs } from "file-saver";
import Papa from "papaparse"; // Import PapaParse
import moment from "moment";
import logo from "../logo.png";
import qrCodeUrl from "./gpay.jpeg";

const { Title } = Typography;
const { Option } = Select;

const Home = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [totalStockUsed, setTotalStockUsed] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [form] = Form.useForm();
  const [productOptions, setProductOptions] = useState([]);
  const [totalAdvance, setTotalAdvance] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);
  const [customerDetails, setCustomerDetails] = useState(null);


  useEffect(() => {
    fetchInvoices();
    fetchProducts();
    console.log("Fetching invoices", customerDetails);
  }, []);

  useEffect(() => {
    const filtered = invoices.filter((invoice) =>
      invoice.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredInvoices(filtered);

    // Calculate total advance and remaining amounts for filtered invoices
    const advance = filtered.reduce((sum, invoice) => {
      const advanceAmount = parseFloat(invoice.advance || 0);
      return sum + advanceAmount;
    }, 0);

    const remaining = filtered.reduce((sum, invoice) => {

      const paidAmount = parseFloat(invoice.paid_amount || 0);  // Adding paid_amount

      return sum + (paidAmount);  // Subtract paid_amount from totalAmount and advanceAmount
  }, 0);


    const totalAmount = filtered.reduce((sum, invoice) => {
      return sum + parseFloat(invoice.total_amount || 0);
    }, 0);

    setTotalAdvance(advance);
    setTotalRemaining(remaining);
    setTotalInvoiceAmount(totalAmount);
  }, [searchQuery, invoices]);


  const fetchRemainingAmount = async (customerId) => {
    try {
      const response = await fetch(`https://yogigraphics.in/backend/get_remaining_amount.php?customer_name=${customerId}`);
      const data = await response.json();
      console.log("Remaining amount:", data.total_paid_amount);
      setCustomerDetails(data);
    } catch (error) {
      console.error("Failed to fetch remaining amount:", error);
      setCustomerDetails(null);
    }
  };


  const fetchProducts = async () => {
    try {
      const response = await fetch(
        "https://yogigraphics.in/backend/get_product.php"
      );
      const result = await response.json();
      if (result.success) {
        setProductOptions(result.data);
      } else {
        notification.error({ message: "Error", description: result.message });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to fetch products",
      });
    }
  };

  const fetchInvoices = async () => {
    try {
      const response = await fetch(
        "https://yogigraphics.in/backend/get_invoice.php"
      );
      const result = await response.json();

      if (result.success) {
        const data = result.data;
        setInvoices(data);

        const invoiceAmount = data.reduce((sum, invoice) => {
          const totalAmount = parseFloat(invoice.total_amount || 0);
          const discount = parseFloat(invoice.discount || 0);
          return sum + (totalAmount - totalAmount * (discount / 100));
        }, 0);

        const stockUsed = data.reduce((sum, invoice) => {
          return (
            sum +
            (invoice.items
              ? invoice.items.reduce(
                  (productSum, product) => productSum + (product.quantity || 0),
                  0
                )
              : 0)
          );
        }, 0);

        setTotalInvoiceAmount(invoiceAmount);
        setTotalStockUsed(stockUsed);
      } else {
        notification.error({ message: "Error", description: result.message });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to fetch invoices",
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      render: (_, __, index) => index + 1, // Render row number starting from 1
      width: 60,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
    },
    { title: "Customer", dataIndex: "customer_name", key: "customer_name" },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (amount) => `₹${parseFloat(amount || 0).toFixed(2)}`,
    },
    {
      title: "Advance Payment",
      key: "advance_payment",
      render: (text, record) =>
        `₹${parseFloat(record.advance || 0).toFixed(2)}`,
    },
    {
      title: "Remaining Payment",
      key: "paid_amount",
      render: (text, record) =>
        `₹${parseFloat(record.paid_amount || 0).toFixed(2)}`,
    },
    {
      title: "Products",
      dataIndex: "items",
      key: "items",
      render: (items) =>
        items
          ? items.map((item) => (
              <div key={item.id}>
                {item.product_name}: {item.quantity}
              </div>
            ))
          : null,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Button
            onClick={() => editInvoice(record)}
            style={{ marginRight: 8 }}
          >
            Edit
          </Button>

          <Popconfirm
            title="Are you sure you want to delete this invoice?"
            onConfirm={() => deleteInvoice(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const editInvoice = (invoice) => {
    setEditingInvoice(invoice);
    fetchRemainingAmount(invoice.customer_name);

    form.setFieldsValue({
      invoiceNumber: invoice.invoice_number,
      customer: invoice.customer_name,
      address: invoice.address || "",
      number: invoice.phone_number || "",
      invoiceDate: moment(invoice.created_at),
      discount: invoice.discount || 0,
      advance: invoice.advance || 0, // Handle advance field
      paid_amount: invoice.paid_amount || 0, // Handle paid_amount field
      total_amount: invoice.total_amount || 0, // Handle paid_amount field
      products: invoice.items
        ? invoice.items.map((item) => ({
            key: item.id,
            product: item.product_name,
            quantity: item.quantity,
            price: item.price,
          }))
        : [],
      narration: invoice.narration || "",
    });
  };

  const handleUpdate = async (values) => {
    try {
      const response = await fetch(
        "https://yogigraphics.in/backend/update_invoice.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            invoiceId: editingInvoice.id,
            invoiceNumber: values.invoiceNumber,
            customer: values.customer,
            address: values.address,
            number: values.number,
            invoiceDate: values.invoiceDate.format("YYYY-MM-DD"),
            discount: values.discount,
            advance: values.advance, // Send advance field
            paid_amount: values.paid_amount, // Send the remaining amount
            products: values.products || [],
            narration: values.narration,
          }),
        }
      );

      const result = await response.json();

      if (result.success) {
        notification.success({
          message: "Success",
          description: "Invoice updated successfully",
        });
        setEditingInvoice(null);
        form.resetFields();
        fetchInvoices(); // Refetch invoices to reflect changes
      } else {
        notification.error({ message: "Error", description: result.message });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to update invoice",
      });
    }
  };

  const deleteInvoice = async (id) => {
    try {
      const response = await fetch(
        "https://yogigraphics.in/backend/delete_invoice.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id }),
        }
      );

      const result = await response.json();

      if (result.success) {
        notification.success({
          message: "Success",
          description: "Invoice deleted successfully",
        });
        fetchInvoices(); // Refetch invoices to reflect changes
      } else {
        notification.error({ message: "Error", description: result.message });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to delete invoice",
      });
    }
  };

  const downloadCSV = () => {
    const csvData = filteredInvoices.map((invoice) => ({
      "Invoice Number": invoice.invoice_number,
      Customer: invoice.customer_name,
      Date: moment(invoice.created_at).format("YYYY-MM-DD"),
      "Total Amount": `₹${parseFloat(invoice.total_amount || 0).toFixed(2)}`,
      "Advance Payment": `₹${parseFloat(invoice.advance || 0).toFixed(2)}`,
      "Remaining Payment": `₹${(
        parseFloat(invoice.total_amount || 0) - parseFloat(invoice.advance || 0)
      ).toFixed(2)}`,
      Products: invoice.items
        ? invoice.items
            .map((item) => `${item.product_name}: ${item.quantity}`)
            .join(", ")
        : "",
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "invoices.csv");
  };
  const printInvoice = () => {
      if (!editingInvoice) return;
      const discount = parseFloat(editingInvoice.discount) || 0;
      const advance = parseFloat(editingInvoice.advance) || 0;
      const totalAmount = parseFloat(editingInvoice.total_amount) || 0;

      const invoiceHtml = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Invoice</title>
          <style>
              @page {
                  size: A4;
                  margin: 20mm;
              }
              body {
                  font-family: Arial, sans-serif;
                  color: #333;
                  margin: 0;
                  padding: 0;
                  box-sizing: border-box;
                  background-color: #f9f9f9;
              }
              .container {
                  width: 100%;
                  max-width: 800px;
                  margin: 0 auto;
                  padding: 20px;
                  border: 1px solid #ccc;
                  background-color: #fff;
                  border-radius: 8px;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                  position: relative;
              }
              .header {
                  text-align: center;
                  margin-bottom: 20px;
                  border-bottom: 2px solid #333;
                  padding-bottom: 10px;
              }
              .header img {
                  max-width: 150px;
              }
              .header h1 {
                  margin: 0;
                  font-size: 26px;
                  text-transform: uppercase;
                  color: #333;
              }
                  .company-details, .invoice-details {
                  margin-bottom: 20px;
                  font-size: 14px;
              }
              .company-details p, .invoice-details p {
                  margin: 5px 0;
              }
              .company-details {
                  border-bottom: 1px solid #ddd;
                  padding-bottom: 10px;
              }
                  .invoice-details {
                  border-bottom: 1px solid #ddd;
                  padding-bottom: 10px;
              }
              .items-table {
                  width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 20px;
                  font-size: 12px;
              }
              .items-table th, .items-table td {
                  padding: 10px;
                  text-align: left;
                  border: 1px solid #ddd;
              }
              .items-table th {
                  background-color: #f4f4f4;
                  font-weight: bold;
              }
              .total-row {
                  font-weight: bold;
                  text-align: right;
                  border-top: 2px solid #333;
              }
              .footer {
                  font-size: 12px;
                  text-align: center;
                  margin-top: 20px;
                  padding-top: 10px;
                  border-top: 1px solid #ddd;
              }
              .footer p {
                  margin: 5px 0;
                  color: #666;
              }
              .amount-words {
                  font-style: italic;
                  font-size: 12px;
                  margin-top: 10px;
                  text-align: left;
              }
              .signatory {
                  margin-top: 40px;
                  text-align: right;
                  font-size: 12px;
              }
              .qr-code {
                  position: absolute;
                  bottom: 20px;
                  left: 20px;
                  max-width: 100px;
              }
          </style>
      </head>
      <body>
          <div class="container">
              <!-- Header with Company Logo and Title -->
              <div class="header">
                  <img src="${logo}" alt="Company Logo" />
                  <h1>Invoice</h1>
              </div>

              <!-- Company and Invoice Details -->
              <div class="company-details">
                  <p><strong>Company:</strong> Yogi Graphics</p>
                  <p><strong>Address:</strong> 8 Ramnath Mahadev Shopping Center</p>
                  <p><strong>Address:</strong>Nr. Busstand, Zakli Talav, Borsad 388580</p>
                  <p><strong>Phone:</strong> +91 9825514255</p>
                  <p><strong>Email:</strong> yogiborsad@gmail.com</p>
                  <p><strong>Jurisdiction:</strong> Borsad, Gujarat</p>


             </div>
             <div class="invoice-details">

                  <p><strong>Invoice Number:</strong> ${
                    editingInvoice.invoice_number || "N/A"
                  }</p>
                  <p><strong>Date:</strong> ${
                    moment(editingInvoice.created_at).format("DD-MMM-YY") || "N/A"
                  }</p>
                  <p><strong>Customer:</strong> ${
                    editingInvoice.customer_name
                  }</p>
                  <p><strong>Address:</strong> ${editingInvoice.address || ""}</p>
                  <p><strong>Phone Number:</strong> ${
                    editingInvoice.phone_number || ""
                  }</p>
                  <p><strong>Discount:</strong> ${discount || ""}</p>
                  <p><strong>Advance:</strong> ${advance || ""}</p>
                  <p><strong>Narration:</strong> ${
                    editingInvoice.narration || "No Narrations"
                  }</p>
                  <p><strong>Previous Remaining Amount:</strong> ₹${customerDetails.total_paid_amount}</p>

              </div>

              <!-- Items Table -->
              <table class="items-table">
                  <thead>
                      <tr>
                          <th>Sl No.</th>
                          <th>Description of Goods</th>
                          <th>Quantity</th>
                          <th>Rate</th>
                          <th>Amount</th>
                      </tr>
                  </thead>
                  <tbody>
                      ${
                        editingInvoice.items
                          ? editingInvoice.items
                              .map((item, index) => {
                                const price = parseFloat(item.price) || 0;
                                const quantity = parseInt(item.quantity, 10) || 0;
                                const total = (quantity * price).toFixed(2);
                                return `
                              <tr>
                                  <td>${index + 1}</td>
                                  <td>${item.product_name || ""}</td>
                                  <td>${quantity} ${item.unit || ""}</td>
                                  <td>${price.toFixed(2)}</td>
                                  <td>${total}</td>
                              </tr>
                          `;
                              })
                              .join("")
                          : ""
                      }
                      <tr class="total-row">
                          <td colspan="4">Total</td>
                          <td>${parseFloat(totalAmount || 0).toFixed(2)}</td>
                      </tr>
                  </tbody>
              </table>



                <!-- Footer -->
                <div class="footer">
                    <p>This is a Computer Generated Invoice</p>
                </div>

                <!-- Signatory -->
                <div class="signatory">
                    <p>for Yogi Graphics</p>
                    <p>Authorised Signatory</p>
                </div>

                <!-- QR Code for Google Pay -->
                <img src="${qrCodeUrl}" alt="Google Pay QR Code" class="qr-code" />
            </div>
      </body>
      </html>
      `;

      const printWindow = window.open("", "", "height=600,width=800");
      printWindow.document.open();
      printWindow.document.write(invoiceHtml);
      printWindow.document.close();
      printWindow.print();
    };




  return (
    <div>
      <Title level={2}>Invoices</Title>
      <Button type="primary" onClick={downloadCSV} style={{ marginBottom: 16 }}>
        Download CSV
      </Button>
      <Input.Search
        placeholder="Search by customer name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      {loading ? (
        <Spin />
      ) : (
        <Table columns={columns} dataSource={filteredInvoices} rowKey="id" />
      )}
      <Modal
        title="Edit Invoice"
        visible={!!editingInvoice}
        onCancel={() => setEditingInvoice(null)}
        footer={[
          <Button key="print" type="primary" onClick={printInvoice}>
            Print
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {editingInvoice ? "Update" : "Create"}
          </Button>,
          <Button key="cancel" onClick={() => setEditingInvoice(null)}>
            Cancel
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={handleUpdate}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="invoiceNumber"
                label="Invoice Number"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="customer"
                label="Customer"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="address" label="Address">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="number" label="Phone Number">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="invoiceDate"
                label="Date"
                rules={[{ required: true }]}
              >
                <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="discount" label="Discount">
                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="advance" label="Advance Payment">
                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="narration" label="Narration">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* New paid_amount field */}
          <Row gutter={16}>
          <Col span={12}>
              <Form.Item
                name="total_amount"
                label="Total Amount"

                rules={[
                  { required: true, message: "Please enter the  amount",  },
                ]}
              >
                <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="paid_amount"
                label="Remaining Amount"
                rules={[
                  { required: true, message: "Please enter the paid amount" },
                ]}
              >
                <InputNumber min={0} step={0.01} style={{ width: "100%" }}
                  onChange={(value) => {
                    form.setFieldsValue({ paid_amount: value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Product form fields */}
          <Form.Item name="products" label="Products">
            <Form.List name="products">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row gutter={20} key={key}>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          label={`Product ${key + 1}`}
                          name={[name, "product"]}
                          fieldKey={[fieldKey, "product"]}
                          rules={[
                            { required: true, message: "Missing product" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          label={`Quantity ${key + 1}`}
                          name={[name, "quantity"]}
                          fieldKey={[fieldKey, "quantity"]}
                          rules={[
                            { required: true, message: "Missing quantity" },
                          ]}
                        >
                          <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          label={`Price ${key + 1}`}
                          name={[name, "price"]}
                          fieldKey={[fieldKey, "price"]}
                          rules={[{ required: true, message: "Missing price" }]}
                        >
                          <InputNumber
                            min={0}
                            step={0.01}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Button
                          style={{ width: "100%", marginTop: "30px" }}
                          type="default"
                          onClick={() => remove(name)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                    >
                      Add Product
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        <p>Total Invoice Amount: ₹{totalInvoiceAmount.toFixed(2)}</p>
        <p>Total Stock Used: {totalStockUsed}</p>
        <p>Total Advance: ₹{totalAdvance.toFixed(2)}</p>
        <p>Total Remaining: ₹{totalRemaining.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default Home;
